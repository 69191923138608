.log-detail div {
  padding: 0px 10px;
  box-sizing: border-box;
}

.log-detail div > h3 {
  text-align: center;
}

.log-detail div > pre {
  height: calc(100% - 60px);
  padding: 20px;
  background-color: #eee;
  box-sizing: border-box;
  border-radius: 1rem;
}
